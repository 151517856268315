import PropTypes from "prop-types";
import React from "react";

class BootFooter extends React.Component {
  render() {
    return (
      <footer className="container">
        <p>{this.props.footcontent}</p>
      </footer>
    );
  }
}

BootFooter.propTypes = {
  footcontent: PropTypes.string
};

BootFooter.defaultProps = {
  footcontent: "© Scafflow 2018–2025"
};

export default BootFooter;
